import registerComponents from '../../../../maharo';
import StackProfileMobile from '../containers/stack-profile-mobile';
import resolvers from '../../../data/stack-profile/resolvers';
import {withMobile} from '../../../shared/enhancers/mobile-enhancer';
import {compose} from 'react-apollo';
import {withRouter} from '../../../shared/enhancers/router-enhancer';
import {routes} from './routes';
import {withCurrentUser} from '../../../shared/enhancers/current-user-enhancer';
import {privateMode, user} from '../../../data/shared/queries';
import withStackProfile from '../enhancers/stack-profile';
import {hot} from 'react-hot-loader/root';
import {withPrivateMode} from '../../../shared/enhancers/private-mode-enchancer';
import withErrorBoundary from '../../../shared/enhancers/error-boundary';
import {withApolloContext} from '../../../shared/enhancers/graphql-enhancer';
import withAllTags from '../../../shared/enhancers/all-tags';
import withStackTags from '../enhancers/tags';
import withOrganization from '../../../shared/enhancers/organization-enhancer';

const client = algoliasearch(process.env.ALGOLIA_ID, process.env.ALGOLIA_CLIENT_SEARCH_API_KEY);
client.clearCache();
const searchIndex = client.initIndex(process.env.ALGOLIA_SEARCH_INDEX);

registerComponents(
  {
    StackProfileMobile: compose(
      withApolloContext,
      withMobile(true),
      withRouter(routes, ({route}) => route),
      withCurrentUser(user),
      withPrivateMode(privateMode),
      withOrganization(false),
      withStackProfile,
      withAllTags,
      withStackTags,
      withErrorBoundary()
    )(hot(StackProfileMobile))
  },
  resolvers(searchIndex)
);
