import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {WHITE, TARMAC, FOCUS_BLUE, ASH} from '../../../../shared/style/colors';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {
  SP_CLICKED_NAV_ITEM,
  TAB_STACK,
  TAB_DECISIONS,
  TAB_JOBS,
  TAB_TEAM,
  PRIVATE_TEAM,
  CONTRIBUTORS,
  APPS
} from '../../constants';
import {capitalize} from '../../../../shared/utils/string';
import PrivatePublicIndicator from '../../../../shared/library/private-public-indicator';
import {ORG_STRUCTURE_MODES} from '../../../../shared/enhancers/organization-enhancer';

const PrivPubContainer = glamorous.div({
  marginLeft: 15
});

const Container = glamorous.div({
  ...BASE_TEXT,
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  background: WHITE,
  position: 'sticky',
  top: 61,
  zIndex: 99
});

const NavItem = glamorous.a(
  {
    fontSize: 14,
    lineHeight: 20 / 14,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: TARMAC,
    padding: 9,
    flexGrow: 1
  },
  ({active}) => ({
    borderBottom: active ? `2px solid ${FOCUS_BLUE}` : `1px solid ${ASH}`,
    color: active ? FOCUS_BLUE : TARMAC,
    fontWeight: active ? WEIGHT.BOLD : WEIGHT.NORMAL,
    paddingBottom: active ? 8 : 9
  })
);

const getInitialActiveTab = () => {
  if (typeof window !== 'undefined') {
    const hash = window.location.hash.substr(1);
    if (hash.length > 0) {
      return hash;
    }
  }
  return TAB_STACK;
};

const StickyNav = ({
  showJobs,
  showTeam,
  showPrivateTeam,
  sendAnalyticsEvent,
  showContributors,
  showApps,
  orgStructureMode
}) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(getInitialActiveTab());
    }
  }, null);

  return (
    <Container>
      <NavItem
        href="#stack"
        active={activeTab === TAB_STACK}
        onClick={() => {
          sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_STACK});
          setActiveTab(TAB_STACK);
        }}
      >
        {capitalize(TAB_STACK)}
      </NavItem>
      <NavItem
        href="#decisions"
        active={activeTab === TAB_DECISIONS}
        onClick={() => {
          sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_DECISIONS});
          setActiveTab(TAB_DECISIONS);
        }}
      >
        {capitalize(TAB_DECISIONS)}
      </NavItem>
      {showJobs && (
        <NavItem
          href="#jobs"
          active={activeTab === TAB_JOBS}
          onClick={() => {
            sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_JOBS});
            setActiveTab(TAB_JOBS);
          }}
        >
          {capitalize(TAB_JOBS)}
        </NavItem>
      )}
      {showApps && (
        <NavItem
          href="#apps"
          active={activeTab === APPS}
          onClick={() => {
            sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: APPS});
            setActiveTab(APPS);
          }}
        >
          {capitalize(APPS)}
          <PrivPubContainer>
            <PrivatePublicIndicator typeIndicator="BlueRoundSmall" />
          </PrivPubContainer>
        </NavItem>
      )}
      {showPrivateTeam && orgStructureMode !== ORG_STRUCTURE_MODES.flexible && (
        <NavItem
          href="#privateTeams"
          active={activeTab === PRIVATE_TEAM}
          onClick={() => {
            sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: PRIVATE_TEAM});
            setActiveTab(PRIVATE_TEAM);
          }}
        >
          {capitalize(PRIVATE_TEAM)}
          <PrivPubContainer>
            <PrivatePublicIndicator typeIndicator="BlueRoundSmall" />
          </PrivPubContainer>
        </NavItem>
      )}
      {showContributors && (
        <NavItem
          href="#contributors"
          active={activeTab === CONTRIBUTORS}
          onClick={() => {
            sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_TEAM});
            setActiveTab(CONTRIBUTORS);
          }}
        >
          {capitalize(CONTRIBUTORS)}
          <PrivPubContainer>
            <PrivatePublicIndicator typeIndicator="BlueRoundSmall" />
          </PrivPubContainer>
        </NavItem>
      )}
      {showTeam && (
        <NavItem
          href="#team"
          active={activeTab === TAB_TEAM}
          onClick={() => {
            sendAnalyticsEvent(SP_CLICKED_NAV_ITEM, {item_name: TAB_TEAM});
            setActiveTab(TAB_TEAM);
          }}
        >
          {capitalize(TAB_TEAM)}
        </NavItem>
      )}
    </Container>
  );
};

StickyNav.propTypes = {
  showJobs: PropTypes.bool,
  showTeam: PropTypes.bool,
  showPrivateTeam: PropTypes.bool,
  sendAnalyticsEvent: PropTypes.func,
  showContributors: PropTypes.bool,
  showApps: PropTypes.bool,
  orgStructureMode: PropTypes.string
};

export default withSendAnalyticsEvent(StickyNav);
