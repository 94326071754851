import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {WHITE} from '../../../shared/style/colors';
import MobileLayout from '../../../shared/library/containers/mobile-layout';
import {NavigationContext, RouteContext} from '../../../shared/enhancers/router-enhancer';
import {truncateText} from '../../../shared/utils/truncate-text';
import FixedNavMobile from '../components/nav/fixed-nav-mobile';
import StickyNavMobile from '../components/nav/sticky-nav-mobile';
import {normaliseOwnerMeta, normaliseTeamMember} from '../utils';
import MoreStacks from '../components/shared/more-stacks';
// import Jobs from '../components/shared/jobs';
import Team from '../components/shared/team';
import StackProfileDecisions from '../components/shared/decisions';
import MobilePortal from '../../../shared/library/modals/base/portal-mobile';
import MobileModal from '../../../shared/library/modals/base/modal-mobile.jsx';
import SidebarSeparator from '../components/sidebar/separator';
import ServiceDetailsCard from '../components/cards/service-details-card';
import {SigninMobileModal} from '../../../shared/library/modals/signin';
import Circular, {LARGE} from '../../../shared/library/indicators/indeterminate/circular';
import {STACK_TYPE_COMPANY} from '../constants';
import {PrivateModeContext} from '../../../shared/enhancers/private-mode-enchancer';
import PrivateTeams from '../components/shared/private-teams';
import {TABLET} from '../../../shared/style/breakpoints';
import {isPrivateMember} from '../../../shared/utils/is-private-member';
import PrivateContributors from '../components/shared/private-contributors';
import Packages from '../components/shared/packages';
import {STACK_TAGS} from '../../../shared/constants/features';
import {featurePermissionCheck} from '../../../shared/utils/feature-permission-check';
import SsoGithubCta from '../../../shared/library/cards/sso-github';
import {ApolloContext} from '../../../shared/enhancers/graphql-enhancer';
import StackItems from '../components/shared/stack-items';
import StackDecisions from '../components/shared/stack-decisions';
import StackDecisionsQuery from '../enhancers/stack-decisions-query';
import StackApps from '../components/shared/stack-apps';
import {
  ORG_STRUCTURE_MODES,
  OrganizationContext
} from '../../../shared/enhancers/organization-enhancer';

const Container = glamorous.div({display: 'flex', flexDirection: 'column', width: '100vw'});

const Content = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: WHITE,
  paddingTop: 16,
  paddingBottom: 50,
  paddingLeft: 10,
  paddingRight: 10,
  width: '100%',
  boxSizing: 'border-box',
  '>h2': {
    paddingTop: 110,
    marginTop: -70
  }
});

const MakeTheFixedMoreSticky = glamorous.div({
  position: 'sticky',
  top: 0,
  zIndex: 100,
  background: WHITE
});

const BaseCardList = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  '>*': {
    marginBottom: 20
  }
});

const StackCards = glamorous(BaseCardList)({
  alignItems: 'center'
});

const TeamCards = glamorous(BaseCardList)({
  '>*': {
    marginBottom: 15
  }
});

const PrivateTeamsCards = glamorous(BaseCardList)(
  {
    display: 'grid',
    '>*': {
      width: 300
    }
  },
  ({gridGap}) => ({
    gridTemplateColumns: `repeat(2, 1fr)`,
    gridGap: `20px ${gridGap}px`,
    [TABLET]: {
      gridTemplateColumns: `repeat(1, 1fr)`
    }
  })
);

// const JobCards = glamorous(BaseCardList)({
//   '>div': {
//     width: '100%'
//   }
// });

const Spinner = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 50
});

const renderActiveToolModal = (
  activeServiceId,
  setActiveService,
  stack,
  activeService,
  handleExit,
  id
) => {
  if (activeService) {
    return (
      <MobilePortal>
        <MobileModal title={stack.name} onDismiss={handleExit(() => setActiveService(null))}>
          <StackDecisionsQuery id={id} activeServiceId={activeServiceId}>
            {(count, decisionsWithService, otherDecisions, decisionsLoading) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 15
                }}
              >
                <ServiceDetailsCard service={activeService} />
                {!decisionsLoading[1] && (
                  <SidebarSeparator
                    iconUrl={activeService.imageUrl}
                    label={`${
                      decisionsWithService.length === 0 ? 'no' : decisionsWithService.length
                    } decision${decisionsWithService.length === 1 ? '' : 's'} · ${
                      activeService.name
                    }`}
                  />
                )}
                {decisionsLoading[1] ? (
                  <Spinner>
                    <Circular size={LARGE} />
                  </Spinner>
                ) : (
                  <StackProfileDecisions decisions={decisionsWithService} />
                )}
              </div>
            )}
          </StackDecisionsQuery>
        </MobileModal>
      </MobilePortal>
    );
  }
  return null;
};

const StackProfileMobile = ({
  loading,
  currentUser,
  stack,
  stackOwnerType,
  activeServiceId,
  setActiveService,
  limitDecisions,
  setLimitDecisions,
  sendAnalyticsEvent,
  toolItems,
  id,
  userId
}) => {
  const privateMode = useContext(PrivateModeContext);
  const client = useContext(ApolloContext);
  const {orgStructureMode} = useContext(OrganizationContext);
  const {ownerSlug, stackSlug, showStages, hideDecisions, signin} = useContext(RouteContext);
  const navigate = useContext(NavigationContext);
  const [limitStacks, setLimitStacks] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);
  // const [jobsCount, setJobsCount] = useState(0);

  useEffect(() => {
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
  }, [ownerSlug, stackSlug]);

  if (signin) {
    return <SigninMobileModal redirect={stack.path} />;
  }

  return (
    <MobileLayout loading={loading} privateMode={privateMode} userId={userId}>
      {handleExit => {
        if (loading) {
          return [null, null];
        }
        // const showJobs = !privateMode && jobsCount > 0;
        const hasTeam = stack.owner.team && stack.owner.team.count > 0;
        const hasOwner = stack.owner.owners ? stack.owner.owners.length > 0 : false;
        const isPrivateCompanyStack = stack?.owner?.privateMode;
        const teamMember = normaliseTeamMember(stack.owner, currentUser, stackOwnerType);
        const ownerMeta = normaliseOwnerMeta(stack, stackOwnerType);
        const isPrivate = isPrivateMember(stack.owner.id, privateMode);
        const showPrivateTeam =
          stack.private &&
          isPrivate &&
          stackOwnerType === STACK_TYPE_COMPANY &&
          stack.privateTeamsCount > 0;
        const hasPrivateContributors = stack.private && stack.contributorsCount > 0;
        const showApps = stack && stack.stackApps && stack.stackApps.count > 0;
        const isOwner =
          stackOwnerType === STACK_TYPE_COMPANY ? stack.owner.amIOwner : stack.amIOwner;
        const truncatedDescription =
          stack.description && stack.description.length < 97
            ? stack.description
            : truncateText(stack.description || '', 97, '...');
        const tagsPermission = featurePermissionCheck(privateMode, STACK_TAGS);
        const activeService = activeServiceId
          ? toolItems.find(s => s.id === activeServiceId)
          : null;
        const modal = renderActiveToolModal(
          activeServiceId,
          setActiveService,
          stack,
          activeService,
          handleExit,
          id
        );
        const companies =
          currentUser &&
          currentUser.companies &&
          currentUser.companies.filter(comp => comp.slug === ownerSlug);
        const isAdmin = companies && companies.length > 0 && companies[0].myRole === 'admin';
        const showSsoGithubCta = currentUser && currentUser.shouldForceVcsConnection;
        const forcedVcsProvider = privateMode && privateMode.forcedVcsProvider;

        return [
          <Container key={0}>
            <MakeTheFixedMoreSticky>
              <FixedNavMobile
                teamMember={teamMember}
                type={stackOwnerType}
                {...ownerMeta}
                id={stack.id}
                name={stack.name}
                logoUrl={stack.imageUrl}
                viewCount={stack.viewCount}
                permissions={stack.permissions}
                hasOwner={hasOwner}
                slug={stack.slug}
                services={toolItems}
                description={truncatedDescription}
                isOwner={isOwner}
                embedUrl={stack.embedUrl}
                isCompany={stackOwnerType === STACK_TYPE_COMPANY}
                isPrivate={stack.private}
                path={stack.path}
                websiteUrl={stack.websiteUrl}
                showTags={
                  stack.private &&
                  tagsPermission &&
                  stack.owner &&
                  stack.owner.__typename === 'Company'
                }
              />
            </MakeTheFixedMoreSticky>
            {showSsoGithubCta ? (
              <SsoGithubCta client={client} forcedVcsProvider={forcedVcsProvider} />
            ) : (
              <>
                <StickyNavMobile
                  // showJobs={showJobs}
                  showTeam={hasTeam && !hasPrivateContributors && !isPrivateCompanyStack}
                  showContributors={privateMode && hasPrivateContributors}
                  showPrivateTeam={showPrivateTeam}
                  showApps={showApps}
                  orgStructureMode={orgStructureMode}
                />
                <Content>
                  <div style={{paddingTop: 125, marginTop: -125}} id="stack">
                    <StackItems
                      stack={stack}
                      isPrivate={isPrivate}
                      showStages={showStages}
                      setLimitDecisions={setLimitDecisions}
                      activeServiceId={activeServiceId}
                      setActiveService={setActiveService}
                      toolItems={toolItems}
                    />
                  </div>
                  <StackDecisions
                    activeService={activeService}
                    isOwner={isOwner}
                    ownerName={ownerMeta.ownerName}
                    stack={stack}
                    limitDecisions={limitDecisions}
                    sendAnalyticsEvent={sendAnalyticsEvent}
                    setCount={setCount}
                    activeServiceId={activeServiceId}
                    setActiveService={setActiveService}
                  />
                  <Packages />
                  {!privateMode && (
                    <MoreStacks
                      List={StackCards}
                      ownerName={ownerMeta.ownerName}
                      navigate={navigate}
                      setActiveService={setActiveService}
                      limitStacks={limitStacks}
                      setLimitStacks={setLimitStacks}
                      setLimitDecisions={() => null}
                    />
                  )}

                  {/* {!privateMode && <Jobs List={JobCards} setJobsCount={setJobsCount} />} */}
                  <StackApps ownerSlug={ownerSlug} stackSlug={stackSlug} />

                  {showPrivateTeam && orgStructureMode !== ORG_STRUCTURE_MODES.flexible && (
                    <PrivateTeams
                      List={PrivateTeamsCards}
                      ownerSlug={ownerSlug}
                      stackSlug={stackSlug}
                    />
                  )}
                  {/*this is members although its named teams*/}
                  {hasTeam && !hasPrivateContributors && !isPrivateCompanyStack && (
                    <Team
                      List={TeamCards}
                      full={hideDecisions}
                      ownerSlug={ownerSlug}
                      isPrivate={isPrivate && stack.private}
                      isAdmin={isAdmin}
                      stackSlug={stackSlug}
                    />
                  )}
                  {hasPrivateContributors && privateMode && (
                    <PrivateContributors
                      List={TeamCards}
                      contributors={stack.contributors}
                      full={hideDecisions}
                      ownerSlug={ownerSlug}
                      isPrivate={isPrivate}
                      isAdmin={isAdmin}
                    />
                  )}
                </Content>
              </>
            )}
          </Container>,
          modal
        ];
      }}
    </MobileLayout>
  );
};

StackProfileMobile.propTypes = {
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
  stack: PropTypes.object,
  stackOwnerType: PropTypes.string,
  activeServiceId: PropTypes.string,
  setActiveService: PropTypes.func,
  setLimitDecisions: PropTypes.func,
  toolItems: PropTypes.object,
  limitDecisions: PropTypes.bool,
  sendAnalyticsEvent: PropTypes.func,
  id: PropTypes.string,
  userId: PropTypes.string
};

export default StackProfileMobile;
