import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import ViewsIcon from '../../../../shared/library/icons/views-icon.svg';
import {MAKO} from '../../../../shared/style/colors';
import {formatCount} from '../../../../shared/utils/format';
import {BASE_TEXT} from '../../../../shared/style/typography';
import {PHONE} from '../../../../shared/style/breakpoints';

const Container = glamorous.div({
  marginRight: '15px',
  lineHeight: '34px',
  display: 'flex',
  alignItems: 'center'
});

const Count = glamorous.strong({
  ...BASE_TEXT,
  marginLeft: '8px',
  fontSize: '14px',
  letterSpacing: '0.6px',
  color: MAKO,
  verticalAlign: 'middle',
  [PHONE]: {
    fontSize: 13
  }
});

export default class Views extends PureComponent {
  static propTypes = {
    viewCount: PropTypes.number.isRequired
  };

  render() {
    const {viewCount} = this.props;
    return (
      <Container>
        <ViewsIcon />
        <Count>{viewCount ? formatCount(viewCount) : 0}</Count>
      </Container>
    );
  }
}
