import React from 'react';
import PropTypes from 'prop-types';
import {Query} from 'react-apollo';
import {stackDecisions, stackDecisionsForTool} from '../../../data/stack-profile/queries';
import {normaliseDecisions, normaliseDecisionsCount} from '../utils';
import {MAX_CONNECTIONS, MAX_DECISIONS} from '../constants';

const StackDecisionsQuery = ({id, activeServiceId, children}) => {
  return (
    <Query query={stackDecisions} variables={{id}}>
      {({data: {stackProfile}, loading: loadingMain}) => {
        const count = normaliseDecisionsCount(stackProfile);
        let decisionsWithService = normaliseDecisions(stackProfile);
        let otherDecisions = [];
        return (
          <Query
            query={stackDecisionsForTool}
            variables={{id, first: MAX_CONNECTIONS, filterByToolId: activeServiceId}}
            skip={activeServiceId === null}
          >
            {({data: byTool, loading: loadingByTool}) => {
              if (byTool && byTool.stackProfile) {
                decisionsWithService = normaliseDecisions(byTool.stackProfile);
              }
              return (
                <Query
                  query={stackDecisionsForTool}
                  variables={{id, first: MAX_DECISIONS, filterNotByToolId: activeServiceId}}
                  skip={activeServiceId === null}
                >
                  {({data: notByTool, loading: loadingNotByTool}) => {
                    if (notByTool && notByTool.stackProfile) {
                      otherDecisions = normaliseDecisions(notByTool.stackProfile);
                    }
                    return children(count, decisionsWithService, otherDecisions, [
                      loadingMain,
                      loadingByTool,
                      loadingNotByTool
                    ]);
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

StackDecisionsQuery.propTypes = {
  id: PropTypes.string,
  activeServiceId: PropTypes.string,
  children: PropTypes.func
};

export default StackDecisionsQuery;
