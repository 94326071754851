import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {PAGE_WIDTH} from '../../../../shared/style/dimensions';
import Logo, {LARGE} from './logo';
import Metadata, {BIG} from './metadata';
import Views from './views';
import {PHONE} from '../../../../shared/style/breakpoints';
import Menu from './menu/index';
import {MobileContext} from '../../../../shared/enhancers/mobile-enhancer';
import {TARMAC} from '../../../../shared/style/colors';
import {BASE_TEXT} from '../../../../shared/style/typography';
import ShowTags from '../../../../shared/library/show-tags';

const Container = glamorous.div({
  ...BASE_TEXT,
  display: 'flex',
  width: PAGE_WIDTH,
  height: 75,
  marginTop: 20,
  marginBottom: 20,
  justifyContent: 'center',
  alignItems: 'center',
  [PHONE]: {
    width: '100%',
    height: 'auto',
    marginTop: 14,
    marginBottom: 14
  }
});

const TagsContainer = glamorous.div({
  ...BASE_TEXT,
  display: 'flex',
  width: PAGE_WIDTH,
  height: 75,
  marginTop: 20,
  marginBottom: 20,
  justifyContent: 'center',
  alignItems: 'center',
  [PHONE]: {
    width: '100%',
    height: 'auto',
    marginTop: 14,
    marginBottom: 14,
    marginLeft: 15
  }
});

const LeftContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  [PHONE]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  }
});

const Left = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  margin: '0 0 0 10px',
  flexWrap: 'nowrap'
});

const Right = glamorous.div({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginRight: 10,
  flexWrap: 'nowrap'
});

const Description = glamorous.div({
  ...BASE_TEXT,
  color: TARMAC,
  padding: '5px 0 0 5px'
});

const renderViews = viewCount => (viewCount === 0 ? <></> : <Views viewCount={viewCount} />);

const FixedNavMobile = ({
  type,
  logoUrl,
  name,
  ownerUrl,
  viewCount,
  id,
  permissions,
  slug,
  hasOwner,
  teamMember,
  services,
  isOwner,
  embedUrl,
  isVerifiedStack,
  isPrivate,
  isCompany,
  description,
  showTags,
  path
}) => {
  const isMobile = useContext(MobileContext);

  return (
    <React.Fragment>
      <Container>
        <LeftContainer>
          <Left>
            <Logo size={LARGE} type={type} src={logoUrl} />
            <Metadata
              isVerifiedStack={isVerifiedStack}
              name={name}
              ownerUrl={ownerUrl}
              size={BIG}
              isCompany={isCompany}
            />
          </Left>
        </LeftContainer>
        <Right>
          {renderViews(viewCount)}
          <Menu
            name={name}
            ownerUrl={ownerUrl}
            id={id}
            slug={slug}
            hasOwner={hasOwner}
            permissions={permissions}
            type={type}
            teamMember={teamMember}
            services={services}
            isOwner={isOwner}
            isPrivate={isPrivate}
            embedUrl={embedUrl}
            isMobile={isMobile}
            path={path}
          />
        </Right>
      </Container>
      <Container>
        <Description>{description}</Description>
      </Container>
      <TagsContainer>{showTags && <ShowTags />}</TagsContainer>
    </React.Fragment>
  );
};

FixedNavMobile.propTypes = {
  type: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  ownerUrl: PropTypes.string,
  viewCount: PropTypes.number,
  id: PropTypes.string,
  permissions: PropTypes.object,
  slug: PropTypes.string,
  hasOwner: PropTypes.bool,
  teamMember: PropTypes.bool,
  isOwner: PropTypes.bool,
  services: PropTypes.array,
  embedUrl: PropTypes.string,
  isVerifiedStack: PropTypes.bool,
  isCompany: PropTypes.bool,
  isPrivate: PropTypes.bool,
  path: PropTypes.string,
  description: PropTypes.string,
  showTags: PropTypes.bool
};

export default FixedNavMobile;
