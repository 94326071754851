import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import EmptyLogoCircleIcon from './icons/empty-logo-circle.svg';
import EmptyLogoSquareIcon from './icons/empty-logo-square.svg';
import {CONCRETE} from '../../../../shared/style/colors';
import {PHONE, TABLET} from '../../../../shared/style/breakpoints';
import {STACK_TYPE_COMPANY} from '../../constants';

export const LARGE = 75;
export const MEDIUM = 50;
export const SMALL = 34;

const emptyLogoBase = () => ({
  border: 0,
  borderRadius: 0
});

const imageDimensions = size => ({
  boxSizing: 'border-box',
  height: size === LARGE ? LARGE : SMALL,
  width: size === LARGE ? LARGE : SMALL,
  [TABLET]: {
    height: size === LARGE ? MEDIUM : SMALL,
    width: size === LARGE ? MEDIUM : SMALL
  },
  [PHONE]: {
    height: SMALL,
    width: SMALL
  }
});

const emptyLogo = icon => glamorous(icon)(() => emptyLogoBase(), ({size}) => imageDimensions(size));

const Image = glamorous.img(
  ({type}) => ({
    borderRadius: type === 'user' ? '50%' : '2px',
    border: type === STACK_TYPE_COMPANY ? `solid 1px ${CONCRETE}` : 'none'
  }),
  ({size}) => imageDimensions(size)
);

const EmptyLogoCircle = emptyLogo(EmptyLogoCircleIcon);

const EmptyLogoSquare = emptyLogo(EmptyLogoSquareIcon);

export default class Logo extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf([SMALL, MEDIUM, LARGE]),
    src: PropTypes.string,
    type: PropTypes.string.isRequired
  };

  render() {
    const {size, src, type} = this.props;
    let logo;
    if (src) {
      logo = <Image src={src} size={size} type={type} />;
    } else {
      logo =
        type === STACK_TYPE_COMPANY ? (
          <EmptyLogoSquare size={size} />
        ) : (
          <EmptyLogoCircle size={size} />
        );
    }
    return logo;
  }
}
