import React from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import {CHARCOAL, TARMAC} from '../../../../shared/style/colors';
import {PHONE, TABLET} from '../../../../shared/style/breakpoints';
import {BASE_TEXT, WEIGHT} from '../../../../shared/style/typography';
import {withSendAnalyticsEvent} from '../../../../shared/enhancers/analytics-enhancer';
import {SP_CLICKED_STACK_OWNER} from '../../constants';
import VerfiedIcon from '../../../../shared/library/icons/verified.svg';
import PrivatePublicIndicator from '../../../../shared/library/private-public-indicator';
export const BIG = 'big';
export const LITTLE = 'little';

const Container = glamorous.div({
  ...BASE_TEXT,
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 12,
  [TABLET]: {
    marginLeft: 11
  },
  [PHONE]: {
    marginLeft: 9
  }
});

const Name = glamorous.div(
  {
    fontWeight: WEIGHT.BOLD,
    color: CHARCOAL
  },
  ({size, margin}) => ({
    marginRight: margin,
    fontSize: size === BIG ? 25 : 14,
    [TABLET]: {
      fontSize: size === BIG ? 22 : 13
    },
    [PHONE]: {
      fontSize: size === BIG ? 16 : 11
    }
  })
);

const Details = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

const NameContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center'
});

const Owner = glamorous.a(
  {
    textDecoration: 'none',
    color: TARMAC,
    pointerEvents: 'auto',
    lineHeight: 1
  },
  ({size}) => ({
    fontSize: size === BIG ? 14 : 13,
    [TABLET]: {
      fontSize: size === BIG ? 13 : 11
    },
    [PHONE]: {
      fontSize: size === BIG ? 11 : 9
    }
  })
);

const CheckMark = glamorous(VerfiedIcon)({}, ({size}) => ({
  width: size === BIG ? 14 : 11,
  height: size === BIG ? 14 : 11,
  [TABLET]: {
    width: size === BIG ? 14 : 11,
    height: size === BIG ? 14 : 11
  },
  [PHONE]: {
    width: 11,
    height: 11
  }
}));

const DescriptionContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  color: '#444444',
  marginTop: 5,
  fontWeight: WEIGHT.NORMAL,
  fontSize: 14,
  lineHeight: 1.36
});

const PrivPubContainer = glamorous.div({
  marginLeft: 5
});

const Metadata = ({
  size,
  name,
  ownerUrl,
  isVerifiedStack,
  sendAnalyticsEvent,
  isCompany,
  isPrivate,
  description
}) => {
  return (
    <Container>
      {isCompany ? (
        <Details>
          <NameContainer>
            <Name size={size} margin={6}>
              {name}
            </Name>
            {isPrivate && (
              <PrivPubContainer>
                <PrivatePublicIndicator typeIndicator="BlueRoundLarge" />
              </PrivPubContainer>
            )}
            {!isPrivate && isVerifiedStack && <CheckMark size={size} />}
          </NameContainer>
        </Details>
      ) : (
        <>
          <Name size={size}>{name}</Name>
        </>
      )}
      <Details>
        <Owner
          size={size}
          href={ownerUrl}
          onClick={() => sendAnalyticsEvent(SP_CLICKED_STACK_OWNER)}
        >
          {isCompany ? 'View Company Profile' : 'View User Profile'}
        </Owner>
      </Details>
      <Details>
        <DescriptionContainer>{description}</DescriptionContainer>
      </Details>
    </Container>
  );
};

Metadata.propTypes = {
  size: PropTypes.oneOf([LITTLE, BIG]),
  name: PropTypes.string,
  description: PropTypes.string,
  ownerUrl: PropTypes.string,
  isVerifiedStack: PropTypes.bool,
  sendAnalyticsEvent: PropTypes.func,
  isCompany: PropTypes.bool,
  isPrivate: PropTypes.bool
};

export default withSendAnalyticsEvent(Metadata);
